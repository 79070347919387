import {
  Antichrist,
  AntichristThumb, BloatwareLogo, BloatwareLogoThumb,
  BTHeartbreakersPoster,
  BTHeartbreakersPosterThumb,
  BTPipelinePoster,
  BTPipelinePosterThumb,
  ByTheWay,
  ByTheWayThumb,
  ChangeCommand,
  ChangeCommandThumb,
  DownDown,
  DownDownThumb,
  EMAlbumArt,
  EMAlbumArtThumb, EMLoungeBarPoster, EMLoungeBarPosterThumb, EMPipelinePoster, EMPipelinePosterThumb,
  FirstContactArt,
  FirstContactArtThumb, KCLCSLogo,
  LimestoneCube,
  LimestoneCubeThumb,
  LongWayHome,
  LongWayHomeThumb,
  Myopia,
  MyopiaThumb,
  NoKiteFollyPoster,
  NoKiteFollyPosterThumb, NoKiteLogo,
  ShesAWomanArt,
  ShesAWomanArtThumb,
  SpeedLimitArt,
  SpeedLimitArtThumb, TegaluCatLogo, TegaluCatLogoThumb,
  TheFirewall,
  TheFirewallThumb,
  TINOYSArt,
  TINOYSArtThumb,
  TrojanHorse,
  TrojanHorseThumb, TunkuskaLogo,
  UAMAldershotPoster,
  UAMAldershotPosterThumb,
  UAMFleetPoster,
  UAMFleetPosterThumb,
  UAMFollyPoster,
  UAMFollyPosterThumb, UAMLoungeBarPoster, UAMLoungeBarPosterThumb
} from "../../img/work/media";
import {Logo} from "../../img";

export let homeDesignImages = [
  [Myopia, 'https://open.spotify.com/track/5ZirX2tCwsZsRs30ucUCvd?si=fcb4ab3597664bee', 'Myopia single artwork', MyopiaThumb, 'album'],
  [DownDown, 'https://open.spotify.com/track/24iHaMHAxtZ4WquLc9JduK?si=f567972f85544720', 'Down Down single artwork', DownDownThumb, 'album'],
  [BTPipelinePoster, 'https://www.instagram.com/p/C3-q-Q9sxNO/?utm_source=ig_web_copy_link&img_index=2', 'Big Trousers Pipeline poster', BTPipelinePosterThumb, 'poster'],
  [BTHeartbreakersPoster, 'https://www.instagram.com/p/C1Cs5BmrwcP/?img_index=2', 'Big Trousers Heartbreakers\' poster', BTHeartbreakersPosterThumb, 'poster'],
]

export let albums = [
  [TrojanHorse, 'https://open.spotify.com/album/1SGFn9Llrpn41ZIiPfQpAa?si=a1e1505da8524e74', 'TROJAN HORSE MUSIC EP artwork', TrojanHorseThumb, 'album'],
  [Myopia, 'https://open.spotify.com/track/5ZirX2tCwsZsRs30ucUCvd?si=fcb4ab3597664bee', 'Myopia single artwork', MyopiaThumb, 'album'],
  [ByTheWay, 'https://open.spotify.com/track/2ra1Yf16bxg9vqV34cJKzt?si=14185eb2c96d410d', 'By The Way single artwork', ByTheWayThumb, 'album'],
  [Antichrist, 'https://open.spotify.com/album/3UoYScO2yRxbHGAFhlIxVE?si=OlNfFdNWQeGiS6EheRjBMw', 'Antichrist single artwork', AntichristThumb, 'album'],
  [LimestoneCube, 'https://open.spotify.com/album/7zif8IjtfW6im7lrBWrTZr?si=gkRfIVYpT1yZPfK7CEUNLw', 'ME AND MY MASSIVE LIMESTONE CUBE single artwork', LimestoneCubeThumb, 'album'],
  [ChangeCommand, 'https://open.spotify.com/track/3d9nozAVAxv4fZKA0DZYRL?si=69da000a01134e05', 'Change Command single artwork', ChangeCommandThumb, 'album'],
  [DownDown, 'https://open.spotify.com/track/24iHaMHAxtZ4WquLc9JduK?si=f567972f85544720', 'Down Down single artwork', DownDownThumb, 'album'],
  [TheFirewall, 'https://open.spotify.com/track/0Q64b6LmALIlcZKE0gSwAF?si=c6c997a8b7f04497', 'The Firewall single artwork', TheFirewallThumb, 'album'],
  [LongWayHome, '', 'Long Way Home album artwork', LongWayHomeThumb, 'album'],
  [ShesAWomanArt, 'https://open.spotify.com/track/5Xe7lSFL3rryeFyw8aC0Sn?si=e3f2f31600d64322', "She's A Woman album artwork", ShesAWomanArtThumb, 'album'],
  [EMAlbumArt, 'https://open.spotify.com/album/1vuoC71yP6ma9JVmTx1uPx?si=e259c5ab74a24f7d', 'Electric Milk album artwork', EMAlbumArtThumb, 'album'],
  [SpeedLimitArt, 'https://open.spotify.com/album/3v0cqiDoLfDVCKB5sXHP1s?si=KjidQXnTSXyiILCDK7HpYg', 'Speed Limit single artwork', SpeedLimitArtThumb, 'album'],
  // [WabiSabiArt, 'https://open.spotify.com/album/3JlsGn3OHruuitxzUFBJIm?si=9Iqt0HiVTeGEoWdaXfsIYA', 'Wabi Sabi single artwork', WabiSabiArtThumb, 'album'],
  [FirstContactArt, 'https://open.spotify.com/album/0RG1H7mkrI4TkIQMGcqjgN?si=XXHQ4867Rle16fTo3gKT6g', 'First Contact single artwork', FirstContactArtThumb, 'album'],
  [TINOYSArt, 'https://open.spotify.com/track/6RmRCFNCgoO7sQZdYGjMFW?si=40f729512c6c4352', 'Time Is Never On Your Side (unreleased) single artwork', TINOYSArtThumb, 'album'],
];

export let posters = [
  [NoKiteFollyPoster, 'https://www.instagram.com/p/C4cmplYutwC/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==', 'No Kite Folly poster', NoKiteFollyPosterThumb, 'poster'],
  [BTPipelinePoster, 'https://www.instagram.com/p/C3-q-Q9sxNO/?utm_source=ig_web_copy_link&img_index=2', 'Big Trousers Pipeline poster', BTPipelinePosterThumb, 'poster'],
  [UAMFollyPoster, 'https://www.instagram.com/p/C2t4if7rSDV/?img_index=1', 'Unlucky @ Midnight Folly poster', UAMFollyPosterThumb, 'poster'],
  [BTHeartbreakersPoster, 'https://www.instagram.com/p/C1Cs5BmrwcP/?img_index=2', 'Big Trousers Heartbreakers\' poster', BTHeartbreakersPosterThumb, 'poster'],
  [UAMFleetPoster, 'https://www.instagram.com/p/CwNHMtltx5r/', 'Unlucky @ Midnight Falkner\'s Arms poster', UAMFleetPosterThumb, 'poster'],
  [UAMAldershotPoster, 'https://www.instagram.com/p/CrLh7kJN7ia/', 'Unlucky @ Midnight Funky End poster', UAMAldershotPosterThumb, 'poster'],
  [UAMLoungeBarPoster, 'https://www.instagram.com/p/CiaxHEytG3M/', 'Unlucky @ Midnight Lounge Bar poster', UAMLoungeBarPosterThumb, 'poster'],
  [EMLoungeBarPoster, 'https://www.instagram.com/p/CWqwAPKL1v6/', 'Electric Milk Lounge Bar poster', EMLoungeBarPosterThumb, 'poster'],
  [EMPipelinePoster, 'https://www.instagram.com/p/CR_0DzNIguN/', 'Electric Milk The Pipeline poster', EMPipelinePosterThumb, 'poster'],
  // [EMHeartbreakersPoster, 'Electric Milk Heartbreakers poster', 'https://www.instagram.com/p/CRg92xwrYdN/', EMHeartbreakersPosterThumb, 'poster'],
  // [EMGoodIntentPoster, 'https://www.instagram.com/p/BwhmAA9HRyF/', 'Electric Milk The Good Intent poster', EMGoodIntentPosterThumb, 'poster']
]

export let logos = [
  // [BTLogo, 'https://www.instagram.com/big.trousers', 'Big Trousers logo', null, 'small', 4],
  // [JJHLogo, '', 'JJH Music Tuition logo', null, 'small'],
  [BloatwareLogo, 'https://www.instagram.com/bloatwaresounds', 'Bloatware logo', BloatwareLogoThumb, 'small'],
  [TunkuskaLogo, 'https://www.instagram.com/p/Czy1KD7CxOE/?img_index=1', 'Tungüska logo', null, 'long', 2],
  [TegaluCatLogo, 'https://www.instagram.com/tegalucat/', 'Tegalü Cat logo', TegaluCatLogoThumb, 'small', 5],
  [NoKiteLogo, 'No Kite logo', 'https://www.instagram.com/nokitegram/', null, 'long', 2],
  [KCLCSLogo, 'https://www.instagram.com/kclcybersociety/', "King's College London Cyber Security Society logo", null, 'small', 8],
  [Logo, 'https://ofox.co.uk/design', 'Oliver Fox portfolio logo', null, 'small'],
  // [AlbumdleLogo, 'https://albumdle.com/', 'Albumdle logo', null, 'medium'],
  // [TopSpotsLogo, 'https://topspots.co.uk/', 'Top Spots logo', null, 'small'],
]