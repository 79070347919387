import React, {useEffect, useRef, useState} from 'react';
import './timeline.css';
import './timelineMobile.css';
import {Circle} from "../index";

const Timeline = (props) => {
  let {scrollContainer, page} = props;

  let jobs = [
    ['Web Developer', 'Mosaic Tap', 'Created a website from the ground up for a pub, Mosaic Tap, assisting their outreach to new customers and rebranding to their unique aesthetic.', "May '24", "Present"],
    ['Lab Support Assistant', 'Newcastle University', 'Assisting with a range of modules for stage 1, 2, 3 and 4 computing students at Newcastle University, as well as helping with the hiring process of new lecturers.', "Sep '22", 'Present'],
    ['Freelance Software Developer', 'Business Smart Solutions', 'Developing a timesheet management app for Android, iOS, and Web, to enable better management of timesheets and payments within the company.', "Apr '21", 'Present'],
    ['Extreme Blue Intern', 'IBM', 'Worked as an Extreme Blue intern alongside young professionals with one of IBM\u2019s top clienteles, one of the largest department stores in the UK.', "Jun '22", "Sep '22"],
    ['Virtual Tutor', 'FunTech', 'Providing young students with a deeper understanding of Computer Science, catering to their academic development.', "Jul 20'", "Dec 21'"],
    ['Revenues Assistant', 'Surrey Health Borough Council', 'Created a program to help businesses in the local area and distribute business grants to maximise efficiency during the COVID-19 pandemic.', "Mar '20", "May '20"],
    ['Teacher', 'Code Camp', 'Taught to over a hundred students aged 7-13 in JavaScript and Code Camp\u2019s own programming environment.', "Jul '19", "Jul '20"],
    ['Teaching Assistant', 'Kumon', 'Made sure the department ran smoothly and the students were confident with the work they were completing.', "May '14", "Sep '19"]]

  // Refs
  const circleRefs = useRef([]);
  const timelineRef = useRef(null)

  // States
  const [centerElements, setCenterElements] = useState([]);
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    circleRefs.current = circleRefs.current.slice(0, jobs.length);
  }, [jobs]);

  useEffect(() => {
    let container = scrollContainer.current;
    let previousScrollY = container.scrollY;

    const handleScroll = () => {
      const currentScrollY = container.scrollY;
      const isScrollingUp = previousScrollY > currentScrollY;
      previousScrollY = currentScrollY;

      const updatedCenterElements = [...centerElements];
      circleRefs.current.forEach((ref, index) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          const centerY = window.innerHeight / 2;
          const isInCenter =
            rect.top < centerY + 50 &&
            rect.bottom > centerY - 50;

          if (isInCenter) {
            updatedCenterElements[index] = true;
          }
        }
      });
      setCenterElements(updatedCenterElements);
    };

    container.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      ref={timelineRef}
      className={`timelineContainer${expanded}`}
    >
      {jobs.map((job, index) => {
        return (
          <>
            {(index < 4 && !expanded) || (expanded) || (page === 'about') ?
              <Circle
                key={`circle${index}`}
                title={job[0]}
                company={job[1]}
                desc={job[2]}
                side={index % 2 === 0 ? 'right' : 'left'}
                start={job[3]}
                end={job[4]}
                last={index === jobs.length - 1 ? true : null}
                ref={(el) => (circleRefs.current[index] = el)}
                isInCenter={centerElements[index] || false}
              /> : <></>
            }
            {(index !== jobs.length && expanded) || (index < 4 && !expanded) || (index !== jobs.length - 1 && page === 'about') ? <div
              key={`gap${index}`} className='gapLine'></div> : <></>}
            {(index === 4 && !expanded && page !== 'about') || (index === jobs.length - 1 && expanded) ? <Circle
              key={`circle${index}-expand`}
              title={expanded ? 'See less...' : 'See more...'}
              company={''}
              desc={''}
              side={(index % 2 === 0 && !expanded) || jobs.length % 2 === 0 && expanded ? 'right' : 'left'}
              start={''}
              end={''}
              last={true}
              ref={(el) => (circleRefs.current[index] = el)}
              isInCenter={centerElements[index] || false}
              expand={true}
              expanded={expanded}
              setExpanded={setExpanded}
            /> : <></>}
          </>
        )
      })}
    </div>
  )
}

export default Timeline