import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

const ProjectCard = React.memo((props) => {
  let {project, isVisible, projectRefs, index, setScrollRef, getRandomRotation} = props;
  let [rotation, setRotation] = useState(0);
  let [open, setOpen] = useState(false);
  let navigate = useNavigate();

  if (rotation === 0 && !open) {
    setRotation(getRandomRotation(0, 20, true, index));
  }

  return (
    <div
      onClick={() => {
        setOpen(true);
        setRotation(0)
        setScrollRef(index);
        setTimeout(() => navigate(`/${project.type.toLowerCase()}/${project.urlNav}`), 550);
      }}
      onMouseLeave={() => {
        setOpen(false);
        setScrollRef(null);
      }}
      key={`${project.title}-display-${index}`}
      className={'projectDisplayContainer'}
      style={{
        width: open ? `calc(100% - 50px)` : ``,

        transform: `rotate(${rotation}deg)`,
        opacity: isVisible[index] ? 1 : 0,
        scale: isVisible[index] ? `1` : `0`,
        zIndex: open ? `2` : ``
      }}
      ref={(el) => (projectRefs.current[index] = el)}
    >
      <img
        className='projectDisplayImg'
        src={project.img}
        alt={`${project.title} logo`}/>
      <h1>{project.title}</h1>
    </div>
  )
})

export default ProjectCard;