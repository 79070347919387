import {Home} from "../index";
import './scrollingHome.css';
import './scrollingHomeMobile.css';
import {Parchment} from "../../img";
import {Timeline} from "../../components";
import React, {useEffect} from "react";
import ProjectsInfo from "../projects/ProjectsInfo";
import {useNavigate} from "react-router-dom";
import ProjectHomeDisplay from "../../components/projectHomeDisplay/ProjectHomeDisplay";
import {homeDesignImages} from "../design/designImages";

const ScrollingHome = (props) => {
  let {
    setOpen,
    setDialogContent,
    setDialogStyle,
    isMobile,
    setTitle,
    scrollPosition,
    setScrollPosition,
    scrollContainer
  } = props;
  const navigate = useNavigate();

  let allProjects = ProjectsInfo(isMobile);
  let projects = allProjects.filter(item => item['type'] === 'projects' && item['title'] !== 'BSS Hub');
  let researchInfo = allProjects.filter(item => item['type'] === 'research');

  useEffect(() => {
    setScrollPosition(0)
  }, []);

  return (
    <div className="scrollingContainer">
      <div className={"scrollingWelcomeContainer"}>
        <Home isMobile={isMobile} setTitle={setTitle} scrollPosition={scrollPosition}/>
      </div>

      <div className='aboutContainer contentPageContainer noSelect bodyText'>
        <div className='aboutDesc'>
          <h2>Welcome</h2>
          <div>
            <i>Hi there!</i> 👋 I'm Oliver (Ollie), a Ph.D. student based in Newcastle upon Tyne, researching the AI
            hallucination problem.
            <br/><br/>
            I'm a big believer in building strong relationships with my clients. I take the time to understand your
            unique needs and goals, if you'd like to work together and bring your vision to life, don't hesitate to <a
            href={'mailto:hello@ofox.co.uk'}>get in touch!</a> 👍
          </div>

          <ProjectHomeDisplay
            arr={projects}
            isMobile={isMobile}
            scrollContainer={scrollContainer}
            displayType={'project'}
          />

          <br/>
          <h2>Research</h2>
          Graduated with a <b className={"aboutProjectLink"} onClick={() => {
          setOpen(true)
          setDialogContent([<img className={'parchment'} src={Parchment}
                                 alt={'Parchment showing Oliver graduating with a first-class honours in Computer Science from Newcastle University.'}/>])
          if (isMobile) {
            setDialogStyle({width: "85%", padding: "0"});
          } else {
            setDialogStyle({height: "calc(100vh - 150px)", width: "auto"});
          }
        }}><i>First Class Honours</i> in <i>Master of Computer Science</i> from <i>Newcastle University</i></b> in
          2023 and continuing my academic journey by pursing a Ph.D. in <i>Uncertainty Reasoning with Logical
          Guarantees</i>. I'm just at the beginning of where by research might take me.

          <ProjectHomeDisplay
            arr={researchInfo}
            isMobile={isMobile}
            scrollContainer={scrollContainer}
            displayType={'project'}
          />

          <h2>Design</h2>
          <div>
            Having strong design is important to everyone, even if they don't realise!
          </div>
          <br/><br/>


          <ProjectHomeDisplay
            arr={homeDesignImages}
            scrollContainer={scrollContainer}
            displayType={`homeGrid`}
            setOpen={setOpen}
            setDialogContent={setDialogContent}
            isMobile={isMobile}
            setDialogStyle={setDialogStyle}
          />

          <br/><br/>
          <h2>Work Experience</h2>
          Below is a timeline of my most notable jobs that I've worked from 2014 until the present, detailing my
          progression in developing skills and experience.
        </div>

        <Timeline scrollContainer={scrollContainer}/>
        <br/><br/>

        <div className='aboutDesc bodyText'>
          <h2>Extracurricular / Skills</h2>
          <div>
            Working isn't everything, I believe it's important to take time to embrace hobbies and relax.
          </div>
          <br/>
          <div className={'skillBoxContainer'}>
            <div className={'skillBox noSelect'}>Java ☕</div>
            <div className={'skillBox noSelect'}>Python 🐍</div>
            <div className={'skillBox noSelect'}>C# 🔷</div>
            <div className={'skillBox noSelect'}>Swift 🐦</div>
            <div className={'skillBox noSelect'}>React ⚛️</div>
            <div className={'skillBox noSelect'}>Photoshop 🖼️</div>
            <div className={'skillBox noSelect'}>Illustrator 🎨</div>
            <div className={'skillBox noSelect'}>VEGAS Pro 🎞️</div>
            <div className={'skillBox noSelect'}>Personal Projects 🚀</div>
            <div className={'skillBox noSelect'}>Design Thinking 💡</div>
            <div className={'skillBox noSelect'}>Music 🎵</div>
            <div className={'skillBox noSelect'}>Running 🏃‍♂️</div>
            <div className={'skillBox noSelect'}>Cycling 🚴‍♀️</div>
            <div className={'skillBox noSelect'}>Gigs 🎸‍</div>
          </div>
        </div>

        <div className='aboutDesc checkOut'>
          Why not check out my <i onClick={() => {
          navigate('/projects')
        }} className='aboutProjectLink'>projects</i> if you haven't already!
        </div>
      </div>
    </div>
  )
}

export default ScrollingHome;