import React, {useEffect} from 'react';
import './projectPage.css';
import './projectPageMobile.css';
import './projectPageTablet.css';

import {useLocation, useNavigate} from "react-router-dom";
import {Arrow, Parchment} from "../../img";
import {animatePageLoad} from "../../functions/animatePageLoad";
import OpenImage from "../openImage/OpenImage";

const ProjectPage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  let {
    img, title, shortTitle, intro, desc, screenshots, skills, background, prev, next, imgSize, setOpen,
    setDialogContent, setStyle, isMobile, setTitle, setScrollPosition
  } = props;

  useEffect(() => {
    setTitle(isMobile && shortTitle !== '' ? shortTitle : title); // Set title for navbar
    document.title = `Oliver Fox - ${title}`

    animatePageLoad(['.projectPageImg', '.projectPageIntro', '.projectPageTitleContainer', '.projectPageDesc', '.screenshots', '.projectPageSkills'])
  }, [location, isMobile])

  useEffect(() => {
    setScrollPosition(0)
  }, [])

  return (
    <div className='projectPageContainer noSelect'>
      <div className='projectSwitcher'>
        <div onClick={() => {
          navigate(prev)
        }} className='projectSwitcherInner'>
          <div className='projectButton'>
            <img className='arrow arrowBack' src={Arrow} alt={'Back arrow'}/>
          </div>
        </div>
        <div>
          <div className='switcherTitle'>{title}</div>
        </div>
        <div onClick={() => {
          navigate(next)
        }} className='projectSwitcherInner'>
          <div className='projectButton'>
            <img className='arrow arrowNext' src={Arrow} alt={'Next arrow'}/>
          </div>
        </div>
      </div>

      <div>
        <div className='projectPageLogoContainer'>
          <img className='projectPageImg' src={img} alt={`${title} logo`}/>
          <div className='projectPageIntro'>{intro}</div>
        </div>
        <div className={'projectPageDesc bodyText'}>
          {desc}
        </div>
      </div>
      <div className='screenshots'>
        {screenshots.map((screenshot, index) =>
          <OpenImage
            key={`screenshot-${index}`}
            isMobile={isMobile}
            setOpen={setOpen}
            setDialogContent={setDialogContent}
            img={screenshot[0]}
            alt={screenshot[1]}
            setStyle={setStyle}
            rotated={screenshot[2]}
          />)}
      </div>
      <div className='projectPageSkills bodyText'>
        <h2 className='projectPageTitle'>Skills / Technologies</h2>
        <div>Below are some of the skills and technologies developed in the process of this project:</div>
        {skills}
      </div>


    </div>
  )
}

export default ProjectPage