import React, {useEffect, useState} from 'react';
import anime from "animejs";

import './contactDialog.css';
import './contactDialogMobile.css';
import './contactDialogTablet.css';

const ContactDialog = (props) => {
  let {open, setOpen, content, style, setStyle} = props;

  const [displayStyle, setDisplayStyle] = useState("none");

  useEffect(() => {
    if (open) {
      setDisplayStyle("flex");
      anime({
        targets: '.contactContainer',
        opacity: ['0%', '100%'],
        easing: 'easeInOutQuad',
        duration: 200
      });
    } else {
      anime({
        targets: '.contactContainer',
        opacity: ['100%', '0%'],
        easing: 'easeInOutQuad',
        duration: 200,
        complete: function (anim) {
          if (anim.progress === 100) {
            setDisplayStyle("none");
            setStyle({});
          }
        }
      });
    }
  }, [open]);

  return (
    <div onClick={() => {
      setOpen(false)
    }} style={{display: displayStyle}} className='contactContainer noSelect'>
      <div style={style} onClick={(e) => {
        e.stopPropagation();
      }} className='contactDialogBoxContainer'>
        <button className={'closeDialogBtn'} onClick={() => {
          setOpen(false)
        }}>×</button>
        {content}
      </div>
    </div>
  )
}

export default ContactDialog;