import React from 'react';
import './imageWithLocation.css';
import './imageWithLocationMobile.css';

import anime from "animejs";
import {Location} from "../../img";

const ImageWithLocation = (props) => {
    let {
        imgLocation, img, alt, newWidth, marginTopBefore, marginTopAfter, marginBottomBefore, marginBottomAfter,
        direction, extraClass, isMobile
    } = props;

    if (marginTopBefore == null && marginTopAfter == null) {
        marginTopBefore = -75;
        marginTopAfter = 20;
    }

    if (direction === null) {
        direction = "column"
    }

    return (
        <div className='imgLocContainer' style={{width: `${newWidth}`}}>
            <div className='locPhotoContainer' style={{flexDirection: direction}}>
                <img onMouseEnter={() => {
                    if (!isMobile) {
                        anime({
                            targets: '.locationDiv',
                            marginTop: [marginTopBefore, marginTopAfter],
                            marginBottom: [marginBottomBefore, marginBottomAfter],
                            opacity: ['0%', '100%'],
                            easing: 'spring',
                            duration: 200
                        });
                    }
                }} onMouseLeave={() => {
                    if (!isMobile) {
                        anime({
                            targets: '.locationDiv',
                            marginTop: [marginTopAfter, marginTopBefore],
                            marginBottom: [marginBottomAfter, marginBottomBefore],
                            opacity: ['100%', '0%'],
                            easing: 'spring'
                        });
                    }
                }} alt={alt} className={`locImg ${extraClass} noSelect`} src={img} />
                <div className='locationDiv' style={{marginTop: marginTopBefore, marginBottom: marginBottomBefore}}>
                    <img className='locationPin' src={Location} alt={'Location pin'} />
                    <div className='locationName'>{imgLocation}</div>
                </div>
            </div>
        </div>
    )
}

export default ImageWithLocation