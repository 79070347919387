import anime from "animejs";

export const animatePageLoad = (elements) => {
  let basicTimeline = anime.timeline({
    easing: 'easeInOutQuad'
  });

  elements.map((el, index) => {
    basicTimeline
      .add({
        targets: el,
        translateY: [-10, 0],
        opacity: ['0%', '100%'],
        duration: 400,
      }, `${index > 0 ? `-=${150 + 25 * index}` : ''}`)
  });
}