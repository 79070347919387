import './navbarBubble.css';
import './navbarBubbleMobile.css';
import './navbarBubbleTablet.css';

import {AboutIcon, DesignIcon, HomeIcon, ProjectsIcon, ResearchIcon} from "../../img/home";
import {useNavigate} from "react-router-dom";
import {useEffect, useRef} from "react";

const NavbarBubble = (props) => {
  let {logoImgRef, navBubbleOpen, setNavBubbleOpen, isMobile} = props;
  let navigate = useNavigate();
  const bubbleRef = useRef(null);

  const radius = 25; // Radius of the circle
  const centre = radius; // Center of the circle
  const imageRadius = 4; // Radius of the image (adjust as necessary)

  let navs = [
    ['', HomeIcon],
    ['projects', ProjectsIcon],
    ['research', ResearchIcon],
    ['design', DesignIcon],
    ['about', AboutIcon]
  ];

  const calculatePosition = (angle) => {
    // Convert angle to radians
    const radians = (angle * Math.PI) / 180;
    // Calculate x and y positions
    const x = centre + radius * Math.cos(radians) - imageRadius;
    const y = centre + radius * Math.sin(radians) - imageRadius;
    return { x, y };
  };

  useEffect(() => {
    const handleTouchStart = (event) => {
      if (logoImgRef.current && !logoImgRef.current.contains(event.target) && !bubbleRef.current.contains(event.target)) {
        setNavBubbleOpen(false);
      }
    };

    document.addEventListener('touchstart', handleTouchStart);
    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
    };
  }, [setNavBubbleOpen]);

  return (
    <div
      ref={bubbleRef}
      onMouseEnter={() => setNavBubbleOpen(true)}
      onMouseLeave={() => setNavBubbleOpen(false)}
      onClick={() => {
        if (isMobile) {
          setNavBubbleOpen(!navBubbleOpen)
        }
      }}
      className={`navbarBubble navbarBubble${navBubbleOpen}`}>
      <div className="circle-container">
        {navs.reverse().map((src, index) => {
          const startAngle = 20;
          const endAngle = 160;
          const angle = startAngle + ((endAngle - startAngle) / (navs.length - 1)) * index;
          const {x, y} = calculatePosition(angle);
          return (
            <img
              key={index}
              src={src[1]}
              alt={`img-${index}`}
              className="circle-image"
              style={{top: `${y}px`, left: `${x}px`}}
              onClick={() => {
                navigate(`/${src[0]}`);
                setNavBubbleOpen(false);
              }}
            />
          );
        })}
      </div>
    </div>
  )
}

export default NavbarBubble;