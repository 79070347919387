// Imports
import React, {useEffect, useRef} from 'react';
import anime from "animejs";
import {useLocation, useNavigate} from "react-router-dom";
import { marked } from "marked";
import hljs from "highlight.js";
import cypher from 'highlightjs-cypher'

// Styles
import './gqlLimitations.css';
import './gqlLimitationsMobile.css';
import "highlight.js/styles/atom-one-dark-reasonable.css";

// Images
import {Arrow} from "../../img";
import {
    AliceAndBobCricketGif,
    ChatGPT,
    GQLPoster,
    NoamSentence,
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6,
    Sentences
} from "../../img/projects/gqlLimitations";
import {useIsVisible} from "../../components/useIsVisible/useIsVisible";

// Queries
import {simpleQuery, modifiedComplexQuery, complexQuery} from './queries';
import {animatePageLoad} from "../../functions/animatePageLoad";
import OpenImage from "../../components/openImage/OpenImage";

const GQLLimitations = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const ref = useRef();
    const isVisible = useIsVisible(ref);
    let posterImg = document.getElementById('posterImg');

    const floatingStyle = {
        opacity: isVisible ? "0%" : "100%",
    };

    function getPosterSize() {
        let posterWidth;
        let posterHeight;
        if (posterImg != null) {
            posterWidth = posterImg.clientWidth;
            posterHeight = posterImg.clientHeight;
        }
        return [posterWidth, posterHeight];
    }

    // Props
    let { img, title, shortTitle, intro, desc, skills, background, prev, next, isMobile, setScrollPosition, setTitle, setOpen, setDialogContent, setDialogStyle } = props;

    // References
    let refs = [
        <a
            href={"https://www.ibm.com/topics/ai-hallucinations#:~:text=AI%20hallucination%20can%20streamline%20data,decision%2Dmaking%20and%20risk%20analysis."}>[1]</a>,
        <a href={"https://github.com/madushans/DependenSee"}>[2]</a>,
        <a href={"https://universaldependencies.org/en/dep/"}>[3]</a>,
        <a href={"https://en.wikipedia.org/wiki/Recursion#In_language"}>[4]</a>,
        <a href={"https://neo4j.com/developer/neo4j-apoc/"}>[5]</a>,
        <a href={"https://en.wikipedia.org/wiki/Graph_rewriting"}>[6]</a>
    ];

    //  Cypher
    hljs.registerLanguage('cypher', cypher);

    function scrollToSection(section) {
        document.getElementById(section).scrollIntoView({behavior: 'smooth', block: 'start'});
    }

    useEffect(() => {
        setTitle(isMobile && shortTitle !== '' ? shortTitle : title); // Set title for navbar
        document.title = `Oliver Fox - ${title}`
        animatePageLoad(['.projectPageImg', '.projectPageIntro', '.posterDiv', '.pageSectionPaper']);
    }, [location])

    // Only run once to prevent re-highlighting warning
    useEffect(() => {
        hljs.highlightAll();
        setScrollPosition(0)
    }, []);

    // When poster image has loaded, get the size
    useEffect(() => {
        if (posterImg != null && posterImg.complete) {
            getPosterSize();
        }
    }, [posterImg])

    return (

        <div className='projectPageContainer noSelect'>
            <div className='projectSwitcher'>
                <div onClick={() => {
                    navigate(prev)
                }} className='projectSwitcherInner'>
                    {/*{isMobile ? '' : 'Previous'}*/}
                    <div className='projectButton'>
                        <img className='arrow arrowBack' src={Arrow} alt={'Back arrow'}/>
                    </div>
                </div>
                <div>
                    <div className='switcherTitle'>{title}</div>
                </div>
                <div onClick={() => {
                    navigate(next)
                }} className='projectSwitcherInner'>
                    <div className='projectButton'>
                        <img className='arrow arrowNext' src={Arrow} alt={'Next arrow'}/>
                    </div>
                    {/*{isMobile ? '' : 'Next'}*/}
                </div>
            </div>

            <div style={floatingStyle} onClick={() => {
                scrollToSection('poster')
            }} className={'floatingBackTopBtn'}>↑
            </div>

            <div className='projectPageDesc bodyText'>
                <div className='projectPageLogoContainer'>
                    <img className='projectPageImg' src={img} alt={`${title} logo`}/>
                    <div className='projectPageIntro'>{intro}</div>
                </div>
                <div className={'projectPageDesc bodyText'}>
                    {desc}
                </div>
                <div ref={ref} id={'poster'} className='posterDiv'>
                    <div className='innerPosterDiv'>
                        <div onClick={() => {
                            scrollToSection('pageSection1')
                        }}
                             className='highlightPoster'
                             style={{
                                 marginTop: (getPosterSize()[1] / 4.7),
                                 marginLeft: (getPosterSize()[0] / 50),
                                 height: (getPosterSize()[1] / 7),
                                 width: (getPosterSize()[0] / 2.55)
                             }}
                        ></div>
                        <div onClick={() => {
                            scrollToSection('pageSection2')
                        }}
                             className='highlightPoster'
                             style={{
                                 marginTop: (getPosterSize()[1] / 4.7),
                                 marginLeft: (getPosterSize()[0] / 2.25),
                                 height: (getPosterSize()[1] / 5),
                                 width: (getPosterSize()[0] / 1.85)
                             }}
                        ></div>
                        <div onClick={() => {
                            scrollToSection('pageSection3')
                        }}
                             className='highlightPoster'
                             style={{
                                 marginTop: (getPosterSize()[1] / 2.725),
                                 marginLeft: (getPosterSize()[0] / 50),
                                 height: (getPosterSize()[1] / 5.4),
                                 width: (getPosterSize()[0] / 2.55)
                             }}
                        ></div>
                        <div onClick={() => {
                            scrollToSection('pageSection4')
                        }}
                             className='highlightPoster'
                             style={{
                                 marginTop: (getPosterSize()[1] / 2.4),
                                 marginLeft: (getPosterSize()[0] / 2.25),
                                 height: (getPosterSize()[1] / 3.6),
                                 width: (getPosterSize()[0] / 1.85)
                             }}
                        ></div>
                        <div onClick={() => {
                            scrollToSection('pageSection5')
                        }}
                             className='highlightPoster'
                             style={{
                                 marginTop: (getPosterSize()[1] / 1.56),
                                 marginLeft: (getPosterSize()[0] / 50),
                                 height: (getPosterSize()[1] / 3.5),
                                 width: (getPosterSize()[0] / 2.55)
                             }}
                        ></div>
                        <div onClick={() => {
                            scrollToSection('pageSection6')
                        }}
                             className='highlightPoster'
                             style={{
                                 marginTop: (getPosterSize()[1] / 1.425),
                                 marginLeft: (getPosterSize()[0] / 2.25),
                                 height: (getPosterSize()[1] / 4.45),
                                 width: (getPosterSize()[0] / 1.85)
                             }}
                        ></div>
                        <div onClick={() => {
                            scrollToSection('pageSectionQ')
                        }}
                             className='highlightPoster'
                             style={{
                                 marginTop: (getPosterSize()[1] / 1.78),
                                 marginLeft: (getPosterSize()[0] / 50),
                                 height: (getPosterSize()[1] / 15),
                                 width: (getPosterSize()[0] / 2.55)
                             }}
                        ></div>
                        <img id={'posterImg'} useMap={'#clickable'}
                             alt='Poster for Limitations of Graph Query Languages' className='gqlPoster'
                             src={GQLPoster}/>
                    </div>
                </div>
                <div style={{textAlign: "center"}}>
                    <br/>
                    <i>Click on the various sections to take you to the corresponding section for more detailed
                        description.</i>
                </div>
                <h2 id={'pageSectionPaper'} className='projectPageTitle'>Research Papers</h2>
                <div>
                    If you would like to read more on this, please read the papers below authored
                    by myself and <a href={'https://jackbergus.github.io/'}>Giacomo Bergami</a>.
                </div>
                <h3 id={'pageSectionPaper'} className='projectPageSubtitle'>Generalised Graph Grammars for Natural
                    Language Processing <a href={'https://arxiv.org/abs/2403.07481'} target={"_blank"}>[Paper]</a>
                </h3>
                <div>
                    <i>
                        This seminal paper proposes a new query language for graph matching and rewriting overcoming <b>the
                        declarative</b> limitation
                        of Cypher while outperforming <b>Neo4j</b> on graph matching and rewriting by at least one order
                        of magnitude.
                        We exploited columnar databases (KnoBAB) to represent graphs using the Generalised
                        Semistructured Model.
                    </i>
                </div>
                <h2 className='projectPageTitle'>Poster Sections</h2>
                {desc}
                <h3 id={'pageSection1'} className='projectPageSubtitle'>1. What is "<i>hallucination</i>" in AI?</h3>
                <OpenImage
                  classes={`screenshot mobileImgNoPad sectionImg`}
                  isMobile={isMobile}
                  setOpen={setOpen}
                  setDialogContent={setDialogContent}
                  img={Section1}
                  alt={`Section 1`}
                  setStyle={setDialogStyle}
                />
                <br/>
                <div>
                    AI hallucination is a phenomenon wherein a large language model (LLM)—often a generative AI chatbot
                    or
                    computer vision tool—perceives patterns or objects that are nonexistent or imperceptible to human
                    observers, creating outputs that are nonsensical or altogether inaccurate {refs[0]}.
                    <br/><br/>
                    <OpenImage
                      classes={`screenshot mobileImgNoPad`}
                      isMobile={isMobile}
                      setOpen={setOpen}
                      setDialogContent={setDialogContent}
                      img={ChatGPT}
                      alt={`A conversation with ChatGPT incorrectly stating the last 5 digits of Pi.`}
                      setStyle={setDialogStyle}
                    />
                    <br/>
                    The output from the screenshot above can be seen <a
                  href={"https://chat.openai.com/share/6d6839f0-5d71-418a-908c-4d22be5338b0"}>here</a>.
                </div>

                <h3 id={'pageSection2'} className='projectPageSubtitle'>2. What is the difference between these
                    sentences?</h3>
                <OpenImage
                  classes={`screenshot mobileImgNoPad sectionImg`}
                  isMobile={isMobile}
                  setOpen={setOpen}
                  setDialogContent={setDialogContent}
                  img={Section2}
                  alt={`Section 2`}
                  setStyle={setDialogStyle}
                />
                <br/>
                <div>
                    These graphs were generated using the DependenSee project {refs[1]}, making use
                    of English Dependency Relations {refs[2]}.
                    <br/><br/>
                    <OpenImage
                      classes={`screenshot sentencesSS mobileImg`}
                      isMobile={isMobile}
                      setOpen={setOpen}
                      setDialogContent={setDialogContent}
                      img={Sentences}
                      alt={`Three sentences with graph outputs from DependenSee`}
                      setStyle={setDialogStyle}
                    />
                    <br/>
                    This was useful as a preliminary step of the investigation, however it is not completely foolproof.
                    As shown in sentence <b>C</b>, there should be a dependency where <i>not</i> is, but it has failed
                    to
                    identify it.
                </div>

                <h3 id={'pageSection3'} className='projectPageSubtitle'>3. How are we tackling the problem?</h3>
                <OpenImage
                  classes={`screenshot mobileImgNoPad sectionImg`}
                  isMobile={isMobile}
                  setOpen={setOpen}
                  setDialogContent={setDialogContent}
                  img={Section3}
                  alt={`Section 3`}
                  setStyle={setDialogStyle}
                />
                <br/>
                <div>
                    Noam Chomsky et. al argued that a lack of an upper bound on the number of grammatical sentences and
                    sentence length can be explained as <i>recursion in language</i>, given if the structure of a
                    sentence in
                    which what follows the verb in a given sentence is another sentence {refs[3]}.
                    <br/><br/>
                    <OpenImage
                      classes={`screenshot sentencesSS mobileImg`}
                      isMobile={isMobile}
                      setOpen={setOpen}
                      setDialogContent={setDialogContent}
                      img={NoamSentence}
                      alt={`Noam Chomsky's example of a recursive sentence.`}
                      setStyle={setDialogStyle}
                    />
                    <br/>
                    This is demonstrated by the constructed sentence above, suggesting that this could continue
                    indefinitely
                    as long as we continue this structure of extending the sentence.
                </div>

                <h3 id={'pageSection4'} className='projectPageSubtitle'>4. What does an example look like?</h3>
                <OpenImage
                  classes={`screenshot mobileImgNoPad sectionImg`}
                  isMobile={isMobile}
                  setOpen={setOpen}
                  setDialogContent={setDialogContent}
                  img={Section4}
                  alt={`Section 4`}
                  setStyle={setDialogStyle}
                />
                <br/>
                <div>
                    The first investigation for this research was analysing the sentence <i>"Alice and Bob play
                    cricket"</i>, simple in structure as a good starting point to understand the semantics of Cypher and
                    with it Graph Query Languages (GQLs). Using the output from DependenSee {refs[1]}, this was adapted
                    into a starting graph seen in <b>2</b> in the image above.
                    <br/><br/>
                    <OpenImage
                      classes={`screenshot sentencesSS mobileImg`}
                      isMobile={isMobile}
                      setOpen={setOpen}
                      setDialogContent={setDialogContent}
                      img={AliceAndBobCricketGif}
                      alt={`GIF of the sentence "Alice and Bob play cricket" being transformed.`}
                      setStyle={setDialogStyle}
                    />
                    <br/>
                    Then, an investigation was carried out to find a single query that could be executed to rewrite this
                    graph to achieve what is shown in <b>3</b>. The code below shows the exact query that was
                    constructed to get this output, raising limitations of the language along the way.
                    <br/><br/>
                    The use of the <b>WITH</b> clause is not ideal for our assumption of the language being declarative.
                    Furthermore, the use of User-Defined Functions (UDFs) further dispels how expressive the language
                    is. For example, in order to concatenate the names within the graph, we must use the APOC
                    library {refs[4]}.
                    <div dangerouslySetInnerHTML={{__html: marked(simpleQuery)}}></div>
                </div>

                <h3 id={'pageSection5'} className='projectPageSubtitle'>5. What have we found so far?</h3>
                <OpenImage
                  classes={`screenshot mobileImgNoPad sectionImg`}
                  isMobile={isMobile}
                  setOpen={setOpen}
                  setDialogContent={setDialogContent}
                  img={Section5}
                  alt={`Section 5`}
                  setStyle={setDialogStyle}
                />
                <br/>
                <div>
                    The query and sentence length in the table above refers to the character length of each
                    respectively. This demonstrates that with a ~4.8x increase in sentence length ~8.7x increase in
                    query
                    length, highlighting the insufficiency of Cypher when dealing with complex sentences with multiple
                    nested disjunctions between words.
                    <br/><br/>
                    The final query for rewriting the sentence: <i>"Matt and Tray believe that either Alice and Bob and
                    Carl play cricket or Carl and Dan will not have a way to amuse themselves"</i> resulted in the code
                    below.
                    <br/>
                    <div dangerouslySetInnerHTML={{__html: marked(modifiedComplexQuery)}}></div>
                </div>

                <h3 id={'pageSection6'} className='projectPageSubtitle'>6. What does the future look like?</h3>
                <OpenImage
                  classes={`screenshot mobileImgNoPad sectionImg`}
                  isMobile={isMobile}
                  setOpen={setOpen}
                  setDialogContent={setDialogContent}
                  img={Section6}
                  alt={`Section 6`}
                  setStyle={setDialogStyle}
                />
                <br/>
                <div>
                    After researching and identifying the limitations of GQLs, we will account for these in our newly
                    proposed Generalised Semistructured (Data) Model (GSM) to provide a uniform representation of three
                    data representations, where objects and values are represented by their reference identifier, to be
                    used to insert into collections. Through this, we can express relational tables or graphs with a
                    uniform language, running graph grammars on top of this GSM, with the goal of tackling the issue
                    of negation aforementioned in query answering.
                    <br/>
                </div>

                <h3 id={'pageSectionQ'} className='projectPageSubtitle'>?. Questions you might want to ask?</h3>
                <ul>
                    <li><b>What is a graph grammar?</b></li>
                    A graph grammar, also known as graph rewriting, is a set of graph rewrite rules of the
                    form <b>L → R</b>, with <b>L</b> being called the <i>pattern</i> graph and <b>R</b> being
                    the <i>replacement graph</i> {refs[5]}.

                    <br/><br/>
                    <li><b>What is a graph query language (GQL)?</b></li>
                    Graph Query Languages (GQLs) are similar to a standard query language (a language used for the
                    retrieval of information from a database), with the difference being it is used for graph data
                    management, whereby extracting and defining data that has been modelled as a graph.
                    <br/><br/>
                    There exists some current implementations of GQLs, namely the most widely adopted are: Cypher and
                    SPARQL. Both these languages are ideal for pattern matching on graphs. Cypher was used in this
                    investigation and SPARQL will be investigated in the full paper.

                    <br/><br/>
                    <li><b>Why is this important?</b></li>
                    If we are to continue to use AI as heavily as companies and individuals are right now, then we need
                    to do it right. Relying on "half-baked" intelligences still <i>hallucinating</i> at simple questions
                    like what the last digits of Pi are is not acceptable. This research aims to bring to light the
                    limitations of current query answering technologies and aim to help, if not, fix the problem and
                    give people more intelligent efficient AI capabilities.

                    <br/><br/>
                    <li><b>Is AI going to take over the world?</b></li>
                    No, not in the Terminator sense. At the end of the day, we can always just turn it off.
                </ul>
            </div>
            <div className='projectPageDesc bodyText'>
                <h2 className='projectPageTitle'>Skills / Technologies</h2>
                <div>Below are some of the skills and technologies developed in the process of this project:</div>
                {skills}
            </div>
        </div>
    )
}

export default GQLLimitations