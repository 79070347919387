import './App.css';
import './AppMobile.css';
import './fonts/index.css'

import React, {useEffect, useRef, useState} from "react";
import {Projects, About, Navbar, Design, RLDemo, ContactDialog, ScrollingHome} from "./containers";
import {ProjectPage, SpinLogo} from "./components";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";

import GQLLimitations from "./containers/gqlLimitations/GQLLimitations";
import useWindowDimensions from "./functions/getWindowDimensions";
import ProjectsInfo from "./containers/projects/ProjectsInfo";

function App() {
  let [title, setTitle] = useState('');
  let [open, setOpen] = useState(false);
  let [dialogContent, setDialogContent] = useState("")
  let [dialogStyle, setDialogStyle] = useState({})
  const [isMobile, setIsMobile] = useState(false);

  const {height, width} = useWindowDimensions();

  let allProjects = ProjectsInfo(isMobile);
  let projects = allProjects.filter(item => item['type'] === 'projects');
  let researchInfo = allProjects.filter(item => item['type'] === 'research');

  useEffect(() => {
    setIsMobile(width <= 640);
  }, [width]);

  useEffect(() => {
    mapProjectPaths(projects)
    mapProjectPaths(researchInfo)
  }, [])

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });


  const [scrollPosition, setScrollPosition] = useState(0);
  let scrollContainer = useRef(null);
  let pageContainer = useRef(null);

  useEffect(() => {
    const container = scrollContainer.current;
    const pContainer = pageContainer.current;

    const handleScroll = () => {
      if (container && pageContainer) {
        const { scrollTop, scrollHeight, clientHeight } = container;
        const normalizedScrollPosition = scrollTop / (height / 2);
        const clampedScrollPosition = Math.min(normalizedScrollPosition, 1); // Clamp to 1
        setScrollPosition(clampedScrollPosition);
      }
    };

    if (container && pageContainer) {
      container.addEventListener('scroll', handleScroll);
    }

    if (scrollContainer.current && scrollPosition === 0) {
      scrollContainer.current.scrollTo(0, 0)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollPosition]);

  const mapProjectPaths = (arr) => {
    return arr.map((project, index) => {
        if (project.urlNav !== 'gql-limitations') {
          return (
            <Route key={project.urlNav} exact path={`/${project.type}/${project.urlNav}`} element=
              {<ProjectPage
                img={project.img}
                title={project.title}
                shortTitle={project.shortTitle}
                intro={project.intro}
                desc={project.desc}
                screenshots={project.screenshots}
                skills={project.skills}
                background={''}
                prev={`/${project.type}/${index > 0 ? arr[index - 1].urlNav : arr[arr.length - 1].urlNav}`}
                next={`/${project.type}/${index < arr.length - 1 ? arr[index + 1].urlNav : arr[0].urlNav}`}
                setOpen={setOpen}
                setDialogContent={setDialogContent}
                setStyle={setDialogStyle}
                isMobile={isMobile}
                setTitle={setTitle}
                setScrollPosition={setScrollPosition}
              />}
            />
          )
        }
      }
    )
  }

  return (
    <div className="App">
      <Router>
        <div ref={pageContainer} className='pageContainer'>
          <Navbar setOpen={setOpen} setDialogContent={setDialogContent} setDialogStyle={setDialogStyle} title={title}/>
          <ContactDialog open={open} setOpen={setOpen} content={dialogContent} style={dialogStyle}
                         setStyle={setDialogStyle}/>
          <div ref={scrollContainer} onScroll={SpinLogo} className='innerPageContainer'>
            <Routes>
              {/*<Route exact path="/" element={<Home isMobile={isMobile} setTitle={setTitle}/>}/>*/}
              <Route exact path="/" element={<ScrollingHome
                setOpen={setOpen}
                setDialogContent={setDialogContent}
                setDialogStyle={setDialogStyle}
                isMobile={isMobile}
                setTitle={setTitle}
                scrollPosition={scrollPosition}
                setScrollPosition={setScrollPosition}
                scrollContainer={scrollContainer}
              />}/>
              <Route exact path="/projects"
                     element={
                <Projects isMobile={isMobile} projects={projects} title={'Projects'} setTitle={setTitle}/>
              }/>
              <Route exact path="/research"
                     element={
                <Projects isMobile={isMobile} projects={researchInfo} title={'Research'} setTitle={setTitle}/>
              }/>

              {mapProjectPaths(projects)}
              {mapProjectPaths(researchInfo)}

              <Route exact path="/projects/gql-limitations" element={<Navigate to="/research/gql-limitations" />} />
              <Route exact path="/research/gql-limitations" element=
                {<GQLLimitations
                  img={researchInfo[1].img}
                  title={researchInfo[1].title}
                  shortTitle={researchInfo[1].shortTitle}
                  intro={researchInfo[1].intro}
                  desc={researchInfo[1].desc}
                  skills={researchInfo[1].skills}
                  background={''}
                  prev={`/research/${researchInfo[0].urlNav}`}
                  next={`/research/${researchInfo[2].urlNav}`}
                  setOpen={setOpen}
                  setDialogContent={setDialogContent}
                  setDialogStyle={setDialogStyle}
                  setTitle={setTitle}
                  setScrollPosition={setScrollPosition}
                  isMobile={isMobile}
                />}
              />

              <Route exact path="/projects/dissertation" element={<Navigate to="/research/dissertation" />} />
              {["/demo", "/projects/dissertation/demo"].map((path, index) =>
                <Route key={`path`} exact path={path} element={<RLDemo setTitle={setTitle}/>}/>
              )}

              {["/work/media", "/work/design", "/design"].map((path, index) =>
                <Route key={`path`} exact path={path} element={<Design
                  setOpen={setOpen}
                  setDialogContent={setDialogContent}
                  setDialogStyle={setDialogStyle}
                  isMobile={isMobile}
                  setTitle={setTitle}
                  scrollContainer={scrollContainer}
                  setScrollPosition={setScrollPosition}
                />}/>
              )}
              <Route exact path="/about" element={<About
                setOpen={setOpen}
                setDialogContent={setDialogContent}
                setDialogStyle={setDialogStyle}
                isMobile={isMobile}
                setTitle={setTitle}
                scrollContainer={scrollContainer}
                setScrollPosition={setScrollPosition}
              />}/>
            </Routes>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
