import {AlbumdleLogo, AvgpaceLogo, BSSHub, DissLogo, LassiLogo, MosaicTapLogo, QALogo, TopSpotsLogo} from "../../img";
import React from "react";
import {
    Pipeline,
    PipelineRotated,
    SentenceTransformations,
    SentenceTransformationsRotated
} from "../../img/projects/lassi";
import {
  MosaicCraftBeer,
  MosaicEvents,
  MosaicGallery,
  MosaicLandingPage,
  MosaicTapWallGIF,
  MosaicVisitUs
} from "../../img/projects/mosaicTap";
import {DemoGif, HowToAlbum, ResultsSS, ShareSS} from "../../img/projects/albumdle";
import {TS1, TS2, TS3, TS4} from "../../img/projects/topSpots";
import {AppliedNN, LargeCityCloseUp, LargeCityTraining} from "../../img/projects/thirdYearDiss";
import {BSS1, BSS2, BSSDesktop} from "../../img/work/dev";
import {AvgpaceGif, AvgpaceValues} from "../../img/projects/avgpace";

class Project {
  constructor(type, title, shortTitle = '', titleSize, intro, desc, screenshots, img, urlNav, skills) {
    this.type = type;
    this.title = title;
    this.shortTitle = shortTitle;
    this.titleSize = titleSize;
    this.intro = intro;
    this.desc = desc;
    this.screenshots = screenshots;
    this.img = img;
    this.urlNav = urlNav;
    this.skills = skills;
  }
}

const ProjectsInfo = (isMobile) => {
  return (
    [
      new Project(
        'research',
        'LaSSI: LogicAl, Structural, Semantic text Interpretation',
        'LaSSI',
        isMobile ? '8vw' : '3.4vw',
        ["A novel, vector, graph and Montague grammar based NLP pipeline for sentence similarity."],
        [
            "AI systems learn to make decisions based on training data, leading to bias, LLMs are simply stochastic parrots and do not understand the question you ask; they cannot give rationale behind their answers and so my research aims to give proper logical reasoning behind it. We want to achieve Verified and Explainable AI through combining vector-based, graph-based, and Montague Grammar-based sentence rewriting.", <br/>,<h2>Paper</h2>, <h3>Abstract</h3>, "This paper proposes LaSSI, a Natural Language Processing (NLP) pipeline contextualising verified Artificial Intelligence (AI) by transforming text via Montague Grammars (MGs). We are approaching from the point-of-view of graphs and logic, in which we achieve explainable sentence similarity in terms of Knowledge Base (KB) expansion and possible worlds semantics. Experiments in the present paper excel current state-of-the-art, Graph Retrieval-Augmented Generation (RAG)-based technologies, through a novel method surpassing vector-based and graph-based sentence similarity metrics.", <h3>Publication</h3>,"LaSSI was presented at ",<a href={'https://conferences.sigappfr.org/ideas2024/'}>The 28th International Database Engineered Applications Symposium (IDEAS 2024)</a>," in Bayonne, France; where we gained interest and valid insight into our research instilling confidence in the approach we are taking to ", <i>Verified and Explainable Artificial Intelligence (AI)</i>, ".", <h2>Documents</h2>, "You can read our ", <b>published paper</b>," in the conference proceedings ", <a href={'https://link.springer.com/book/9783031834714'}>here</a>, ".", <br/>, "You can view my ",<b>presentation</b>," for the conference ", <a href={'https://www.researchgate.net/publication/387787497_LaSSI_Logical_Structural_and_Semantic_text_Interpretation_IDEAS_2024_Presentation'}>here</a>, "."
        ],
        [
          [Pipeline, 'Abstraction of the LaSSI pipeline.', PipelineRotated],
          [SentenceTransformations, 'Sentence transformation within the LaSSI pipeline.', SentenceTransformationsRotated],
        ],
        LassiLogo,
        'lassi',
        <ul>
          <li>Verified and Explainable AI</li>
          <li>Natural Language Processing</li>
          <li>Montague Grammar</li>
          <li>Logical Reasoning</li>
          <li>PostgreSQL</li>
          <li>Python</li>
          <li>C++</li>
        </ul>
      ),
      new Project(
        'projects',
        'avgpace',
        '',
        isMobile ? '' : '',
        ["A minimalist average pace conversion website, with ease-of-use and design at the forefront."],
        ["I prefer keeping my Strava activities in /mi, but many of my friends and family use /km. The only websites out there to convert between the two are pace charts that are clunky, full of ads and are laborious to use. So this is a minimalist conversion site, that allows users to easily convert their pace with no hassle. Users can favourite paces, save wherever you left off, and install as a progressive web app.", <br/>, <br/>, "Try out converting your pace ", <a href={'https://avgpace.com'}>here</a>, "."],
        [
          [AvgpaceValues, "Home page for avgpace."],
          [AvgpaceGif, "GIF showing some functionality of avgpace."]
        ],
        AvgpaceLogo,
        'avgpace',
        <ul>
          <li>Vite (React)</li>
          <li>Web Design</li>
          <li>JavaScript</li>
          <li>HTML</li>
          <li>CSS</li>
        </ul>
      ),
      new Project(
        'projects',
        'Mosaic Tap',
        '',
        isMobile ? '' : '',
        ["An interactive website built for the pub ", <b>Mosaic Tap</b>, " in Newcastle upon Tyne."],
        ["Working with the owner of the Mosaic Tap, I created an interactive website that accurately portrays the unique aesthetic of this craft beer house, with a skeuomorphic tap wall mimicking the one present in the pub. The website resides on one minimalist page, so users can scroll around the side without navigating elsewhere and can easily engage with the site. Further, to ensure customers get the most up-to-date information, I created an GUI that enables easy access for the owner to switch out which beers they have on tap at any given time.", <br/>, <br/>, "The website also features a quiz booking system, so that customers can book teams for the weekly Wednesday quiz! From here they'll receive a confirmation email with the ability to update or cancel their booking. A floor plan is then generated so best situate all teams in the pub for the owner to use.", <br/>, <br/>, "If you would like to see the website for yourself, head over to the website ",
          <a href={"https://mosaictap.com/"} rel={"noreferrer"} target="_blank">here</a>, "."],
        [
          [MosaicLandingPage, "Landing page."],
          [MosaicTapWallGIF, "Interactive 'tap wall'."],
          [MosaicEvents, "Posters displayed for the events section."],
          [MosaicGallery, "Gallery of 'hero' images."],
          [MosaicVisitUs, "Mosaic Tap map with opening hours."],
          [MosaicCraftBeer, "Craft beer trails, selected by the owner."],
          // [MosaicWallMobile, "Mosaic Tap's, tap wall displayed on mobile.", "3vw"],
        ],
        MosaicTapLogo,
        'mosaic-tap',
        <ul>
          <li>React</li>
          <li>JavaScript</li>
          <li>HTML</li>
          <li>CSS</li>
          <li>Firebase</li>
          <li>Web Design</li>
        </ul>
      ),
      new Project(
        'research',
        'Limitations of Graph Query Languages',
        'GQL',
        isMobile ? '8vmin' : '4vw',
        ["First research project for my PhD, investigating the limitations of Graph Query Languages (GQLs)."],
        ["Through the use of query answering, natural language processing and newly proposed generalised semistructured models we will analyse sentences through dependency graphs to compare two or more statements, evaluate their truth, and compare to an external, fact-checked knowledge base for clarification. This page poster explores the " +
        "initial understanding of the core theory on the limitations of graph query languages and how we can overcome them, linking to the poster presented at the SAgE PGR Conference."],
        [],
        QALogo,
        'gql-limitations',
        <ul>
          <li>Graph Query Languages</li>
          <li>Neo4j</li>
          <li>SPARQL</li>
          <li>C++</li>
          <li>Graph Grammars</li>
        </ul>),
      new Project(
        'projects',
        'BSS Hub',
        '',
        isMobile ? '' : '',
        ["Timesheet management app for candidates and clients working at Business Smart Solutions."],
        ["My work for ", <i>Business Smart
          Solutions</i>, " has been to create a timesheet management app, on Web, Android and iOS natively. This was undertaken on my own, and has helped grow my skills in all areas of development, especially with working on multiple operating platforms.",
          <br/>, <br/>, "The Web app was developed with React ",
          <i className={'however'}>(Much like this website you're on right
            now)</i>, " which gave me the chance to use skills incorporated with this website on a delivered product.",
          <br/>, <br/>, "For the Android version of the app, it was developed in Java, and in Swift for iOS."],
        [
          [BSS1, 'Home page for BSS Hub on Android.'],
          [BSS2, 'Add hours page for BSS Hub on Android.'],
          [BSSDesktop, 'Add hours page for BSS Hub on Desktop.']
        ],
        BSSHub,
        'bss-hub',
        <ul>
          <li>Android (Java)</li>
          <li>iOS (Swift)</li>
          <li>React</li>
        </ul>),
      new Project(
        'projects',
        'Albumdle',
        '',
        '',
        ["A web application that can be played daily, with the goal to try and guess an album cover, as it's revealed one tile at a time."],
        ["A web application that can be played daily, with the goal to try and guess an album cover, as it's revealed one tile at a time. The player can search through a long list of albums and artists, with up to 16 guesses to get the correct answer.",
          <br/>, <br/>, "If you would like to play the game for yourself, head over to the website ",
          <a href={"https://albumdle.com/"} rel={"noreferrer"} target="_blank">here</a>, "."],
        [
          [DemoGif, "Demonstration of Albumdle being played."],
          [HowToAlbum, "Screenshot of how to play Albumdle."],
          [ShareSS, "Results screen for when you complete a round of Albumdle."],
          [ResultsSS, "Total results that accumulates over several rounds of Albumdle."]
        ],
        AlbumdleLogo,
        'albumdle',
        <ul>
          <li>HTML</li>
          <li>JavaScript</li>
          <li>Firebase</li>
          <li>Web Design</li>
        </ul>),
      new Project(
        'projects',
        'Top Spots',
        '',
        '',
        ['An application I created in lockdown for people to discover and add new ', <i>spots</i>, ' near them.'],
        ['Like many others no wonder, lockdown was an incredibly challenging time for most, being stuck indoors, repeating the same set of tasks day after day. I wanted to occupy myself with something to make the most of this new found time, this is where ',
          <i>Top Spots</i>, ' was born.', <br/>,
          <br/>, "If you would like to check it out for yourself, head over to the website ",
          <a href={"https://topspots.co.uk/"} rel={"noreferrer"}
             target="_blank">here</a>, ", where you'll find a link to the Play Store if you'd like to download."],
        [
          [TS1, ""],
          [TS2, ""],
          [TS3, ""],
          [TS4, ""]
        ],
        TopSpotsLogo,
        'top-spots',
        <ul>
          <li>Android</li>
          <li>Java</li>
          <li>Firebase</li>
          <li>Google Maps / Directions API</li>
        </ul>),
      new Project(
        'research',
        "Bachelor's Dissertation",
        "Dissertation",
        '',
        ['A dissertation project involving using reinforcement learning to train a car to navigate through a city, with obstacles.'],
        ["My third year dissertation project on reinforcement learning. This was an incredibly fun investigation for my third year project, building a procedurally generated city in Unity, then training a neural network on a 'car' to navigate it. The car would be faced with various 'roadblocks' which it would need to avoid in order to reach the given destination.",
          <br/>,
          <br/>, "The results of these tests were then compared to a more traditional, unsupervised learning method to see which performed best.",
          <br/>, <br/>, "If you would like to try the program for yourself, click ",
          <a href={'/projects/dissertation/demo'}>here</a>, "."],
        [
          [LargeCityCloseUp, ""],
          [LargeCityTraining, ""],
          [AppliedNN, ""]
        ],
        DissLogo,
        'dissertation',
        <ul>
          <li>Unity</li>
          <li>C#</li>
          <li>Machine (Reinforcement) Learning</li>
          <li>Supervised Learning (A* with Replanning)</li>
          <li>Data Analysis</li>
        </ul>)
    ]
  )
}

export default ProjectsInfo;