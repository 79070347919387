// Function Imports
import React, {useEffect, useRef, useState} from 'react';
import anime from "animejs";

// Stylesheets
import './home.css';
import './homeMobile.css';

// Images
import {Logo, Profile} from "../../img";

// Components
import {resetLogo, HomeBubble} from "../../components";
import {AboutIcon, DesignIcon, ProjectsIcon, ResearchIcon} from "../../img/home";

const Home = (props) => {
  let { isMobile, setTitle, scrollPosition } = props;
  let [clicked, setClicked] = useState(null);
  let [animating, setAnimating] = useState(null);
  let imageRef = useRef(null)

  let [rndNo, setRndNo] = useState(0);
  let statements = [
    'A developer that loves design.',
    'Developer at night, eggs in the morning.',
    'Ph.D. researching logical reasoning.',
    'Tackling the AI hallucination problem.',
    'A.K.A. Ollie Fox.',
    'First Class MComp Graduate.'
  ];
  let [currentStatement, setCurrentStatement] = useState(statements[rndNo])

  let bubbles = [
    ['projects', ProjectsIcon, 'Projects'],
    ['research', ResearchIcon, 'Research'],
    ['design', DesignIcon, 'Design'],
    ['about', AboutIcon, 'About Me']
  ]

  // Animate page load
  useEffect(() => {
    setTitle('Welcome'); // Set title for navbar

    document.title = `Oliver Fox - Portfolio Website`;
    setRandomStatement();
    resetLogo();

    let basicTimeline = anime.timeline({
      easing: 'easeInOutQuad'
    });

    basicTimeline
      .add({
        targets: '.homeImage',
        translateY: [50, 0],
        opacity: ['0%', '100%'],
        duration: 500,
      })
      .add({
        targets: ".infoText1",
        translateY: [-50, 0],
        opacity: ['0%', '100%'],
        duration: 500,
      }, "-=400")
      .add({
        targets: ".infoText2",
        translateY: [-100, -20],
        opacity: ['0%', '100%'],
        duration: 500,
      }, "-=400");
  }, [])

  // Animate icons on profile click
  useEffect(() => {
    if (!animating) {
      if (clicked) {
        let showTimeline = anime.timeline({
          easing: 'easeInOutQuad',
          begin: function () {
            setAnimating(true);
          },
          complete: function () {
            setRandomStatement();
            setAnimating(false);
          }
        });

        if (isMobile) {
          showTimeline
            .add({
              targets: '.spinningSquare',
              opacity: [0, 1],
              duration: 200,
            })
            .add({
              targets: '.outerSpinningSquare',
              scale: [0.75, 1.5],
              opacity: [0, 1],
              duration: 600,
            })
        } else {
          showTimeline
            .add({
              targets: '.spinningSquare',
              opacity: [0, 1],
              duration: 50,
            })
            .add({
              targets: '.outerSpinningSquare',
              scale: [0.75, 1.5],
              opacity: [0, 1],
              duration: 600,
            })
        }

      } else {
        let hideTimeline = anime.timeline({
          easing: 'easeInOutQuad',
          begin: function () {
            setAnimating(true);
          },
          complete: function () {
            setAnimating(false);
          }
        });

        if (isMobile) {
          hideTimeline
            .add({
              targets: '.outerSpinningSquare',
              scale: [1.5, 0.3],
              opacity: [1, 0],
              duration: 500,
            })
        } else {
          hideTimeline
            .add({
              targets: '.outerSpinningSquare',
              scale: [1.5, 0.3],
              opacity: [1, 0],
              duration: 500,
            })
            .add({
              targets: '.spinningSquare',
              opacity: [1, 0],
              duration: 500,
            })
        }

      }
    }
  }, [clicked, isMobile]);

  useEffect(() => {
    setCurrentStatement(statements[rndNo]);
  }, [rndNo])

  function setRandomStatement() {
    let newRndNum = Math.floor(Math.random() * statements.length)
    while (newRndNum === rndNo) {
      newRndNum = Math.floor(Math.random() * statements.length)
      setRndNo(newRndNum)
    }
    setRndNo(newRndNum)
  }

  useEffect(() => {
    if (scrollPosition > 0) {
      setAnimating(false);
      setClicked(false)
    }
  }, [scrollPosition]);

  return (
    <div className='homeInfoContainer'>
      <img
        style={{'--scroll-position': scrollPosition,
          position: scrollPosition >= 1 ? `fixed` : `sticky`,
          transition: scrollPosition > 0 ? `0s` : `0.5s`
      }}
        ref={imageRef}
        className={`homeImage homeImage${clicked} ${scrollPosition === 0 ? `homeImageHover${clicked}` : ``} noSelect`}
        src={Profile}
        alt={"Oliver Fox graduating at Newcastle University."}
        onClick={() => {
          if (!animating && scrollPosition === 0) {
            setClicked(!clicked)
          }
        }}/>
      <div className={'outerSpinningSquare'}>
        <div className={`spinningSquare spinningSquare${clicked}`}>
          {bubbles.map((bubble, i) => {
            return (
              <HomeBubble key={`bubble${i}`} index={i + 1} nav={bubble[0]} icon={bubble[1]} text={bubble[2]} clicked={clicked}/>
            )
          })}
        </div>
      </div>

      <div className={'emptySquare'}></div>
      <span style={{'--scroll-position': scrollPosition,
        opacity: scrollPosition > 0 ? `${(1 - scrollPosition)}` : ``,
        // transition: scrollPosition > 0 ? `0s` : `0.3s`
      }} className={`infoText1 infoText${clicked} noSelect`}>I'm Oliver Fox.</span>
      <div style={{'--scroll-position': scrollPosition,
        // transition: scrollPosition > 0 ? `0s` : `0.2s`
      }} className='infoContainer'>
        <div style={{opacity: scrollPosition > 0 ? `${(1 - scrollPosition)}` : ``}} className={`infoText2 infoText${clicked}`}>
          {currentStatement}
        </div>
      </div>
    </div>
  )
}

export default Home