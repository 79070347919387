import React, {useEffect, useRef} from 'react';
import './about.css';
import './aboutMobile.css';
import {AboutProfile, Location, Parchment, ThatsMe, ThatsMeUp} from "../../img";
import anime from "animejs";
import {resetLogo, Timeline} from "../../components";
import {useLocation, useNavigate} from "react-router-dom";
import {animatePageLoad} from "../../functions/animatePageLoad";

const About = (props) => {
  let {home, setOpen, setDialogContent, setDialogStyle, isMobile, setTitle, setScrollPosition} = props;
  const navigate = useNavigate();
  const location = useLocation();

  const scrollContainer = useRef(null);

  useEffect(() => {
    !home ? setTitle('About Me') : setTitle('Welcome'); // Set title for navbar

    document.title = `Oliver Fox - About Me`;
    resetLogo();
    animatePageLoad(['.aboutPhotoContainer', '.thatsMe', '.aboutDesc', '.timelineContainer']);
  }, [location])

  useEffect(() => {
    setScrollPosition(0)
  }, [])

  return (
    <div ref={scrollContainer} className='aboutContainer contentPageContainer noSelect bodyText'>
      <div className='meContainer'>
        <img className='thatsMe' src={isMobile ? ThatsMeUp : ThatsMe} alt={"Handwritten text saying that's me"}/>
        <div className='aboutPhotoContainer'>
          <img onMouseEnter={() => {
            if (!isMobile) {
              anime({
                targets: '.locationDiv',
                marginTop: [-75, 20],
                opacity: ['0%', '100%'],
                easing: 'spring',
                duration: 200
              });
            }

          }} onMouseLeave={() => {
            if (!isMobile) {
              anime({
                targets: '.locationDiv',
                marginTop: [20, -75],
                opacity: ['100%', '0%'],
                easing: 'spring',
                duration: 200
              });
            }
          }} alt='Oliver Fox next to the Angel of the North' className='aboutProfile noSelect' src={AboutProfile}/>
          <div className='locationDiv'>
            <img className='locationPin' src={Location} alt={'Location pin'}/>
            <div className='locationName'>Angel of the North</div>
          </div>
        </div>
      </div>

      <div className='aboutDesc'>
        <h2>About Me</h2>
        <div>
          <i>Hi there!</i> 👋 I'm Oliver (Ollie), a Ph.D. student based in Newcastle upon Tyne, researching the AI
          hallucination problem. I've been fascinated by the power of code since I changed the colour of the text
          in CMD to green with my first Toshiba laptop 💻. That early passion led me to hone my skills and apply
          them in every way possible - from teaching others the magic of coding 🪄 to developing solutions for a
          large organisation, and even taking on freelance graphic design projects 🎨.
          <br/><br/>
          I thrive on challenges that push me to grow and learn, and I bring that same dedication to every client
          project. Whether it's building a brand-new website from scratch or revamping an existing one, I'm
          committed to creating something we can both be proud of.
          <br/><br/>
          When I'm not coding, you'll find me lacing up my running shoes 👟, hopping on my bike 🚴‍♂️, or catching a
          live gig 🎸. I believe that a well-rounded life fuels creativity and problem-solving, which ultimately
          benefits my work.
          <br/><br/>
          I'm also a big believer in building strong relationships with my clients. I take the time to understand your unique needs and goals, if you'd like to work together and bring your vision to life, don't hesitate to <a href={'mailto:hello@ofox.co.uk'}>get in touch!</a> 👍
        </div>

        <br/>
        <h2>Education</h2>
        Graduated with a <b className={"aboutProjectLink"} onClick={() => {
        setOpen(true)
        setDialogContent([<img className={'parchment'} src={Parchment}
                               alt={'Parchment showing Oliver graduating with a first-class honours in Computer Science from Newcastle University.'}/>])
        if (isMobile) {
          setDialogStyle({width: "85%", padding: "0"});
        } else {
          setDialogStyle({height: "calc(100vh - 150px)", width: "auto"});
        }
      }}><i>First Class Honours</i> in <i>Master of Computer Science</i> from <i>Newcastle University</i></b> in
        2023 and continuing my academic journey by pursing a Ph.D. in <i>Uncertainty Reasoning with Logical
        Guarantees</i>.

        <br/><br/>
        <h2>Work Experience</h2>
        Below is a timeline of my most notable jobs that I've worked from 2014 until the present, detailing my
        progression in developing skills and experience.
      </div>

      <Timeline scrollContainer={scrollContainer} page={'about'}/>

      <div className='aboutDesc skillBoxContainer bodyText'>
        <h2 className={'skillsTitle'}>Extracurricular / Skills</h2>
        <div className={'skillBoxContainer'}>
          <div className={'skillBox noSelect'}>Java ☕</div>
          <div className={'skillBox noSelect'}>Python 🐍</div>
          <div className={'skillBox noSelect'}>C# 🔷</div>
          <div className={'skillBox noSelect'}>Swift 🐦</div>
          <div className={'skillBox noSelect'}>React ⚛️</div>
          <div className={'skillBox noSelect'}>Photoshop 🖼️</div>
          <div className={'skillBox noSelect'}>Illustrator 🎨</div>
          <div className={'skillBox noSelect'}>VEGAS Pro 🎞️</div>
          <div className={'skillBox noSelect'}>Personal Projects 🚀</div>
          <div className={'skillBox noSelect'}>Design Thinking 💡</div>
          <div className={'skillBox noSelect'}>Music 🎵</div>
          <div className={'skillBox noSelect'}>Running 🏃‍♂️</div>
          <div className={'skillBox noSelect'}>Cycling 🚴‍♀️</div>
          <div className={'skillBox noSelect'}>Gigs 🎸‍</div>
        </div>
      </div>

      <div className='aboutDesc checkOut'>
        Why not check out my <i onClick={() => {
        navigate('/projects')
      }} className='aboutProjectLink'>projects</i> if you haven't already!
      </div>
    </div>
  )
}

export default About