const SpinLogo = event => {
    let logo = document.querySelector('.homeImage');
    if (logo) {
        let scrollTop = event.currentTarget.scrollTop;
        let windowHeight = event.currentTarget.clientHeight;
        let scrollHeight = event.currentTarget.scrollHeight;

        // Calculate the percentage, starting from 0 after 100vh
        let percentage = Math.max(0, (scrollTop - windowHeight) / (scrollHeight - windowHeight * 2));

        logo.style.transform = `rotate(${percentage * 360}deg)`;
    }
};

// Reset logo to 0 degrees if rotated
export function resetLogo() {
    // anime({
    //     targets: '.homeImage',
    //     rotate: [anime.get(document.querySelector('.homeImage'), 'rotate', 'deg'), 0],
    //     duration: 400,
    //     easing: 'easeInOutQuad'
    // });
}

export default SpinLogo;