import React, {useEffect} from 'react';

// Stylesheets
import './design.css';
import './designMobile.css';
import './designTablet.css';

import {
  UpHereImg,
  UpHereImgMobile,
} from "../../img/work/media";

import {ImageWithLocation} from "../../components";
import {useLocation} from "react-router-dom";
import {animatePageLoad} from "../../functions/animatePageLoad";
import ProjectHomeDisplay from "../../components/projectHomeDisplay/ProjectHomeDisplay";
import {albums, logos, posters} from "./designImages";

const Design = (props) => {
  let {setOpen, setDialogContent, setDialogStyle, isMobile, setTitle, scrollContainer, setScrollPosition} = props;
  const location = useLocation();

  let allImages = posters.concat(albums, logos)

  function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  useEffect(() => {
    setTitle('Design'); // Set title for navbar

    document.title = `Oliver Fox - Design`;
    animatePageLoad(['.mediaTopImg', '.mediaAlbumDesc', '.mediaAlbumGrid', '.mediaPosterGrid', '.mediaLogoGrid']);
  }, [location])

  useEffect(() => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTo(0, 0)
    }
  }, [scrollContainer])

  return (
    <div className='mediaContainer bodyText'>
      <ImageWithLocation img={isMobile ? UpHereImgMobile : UpHereImg} imgLocation={'Abbey Road Studios'}
                         alt={"Stood in Studio 3 at Abbey Road Studios"}
                         newWidth={isMobile ? '100%' : 'max(45vw, 500px)'}
                         direction={'column-reverse'} marginTopBefore={0} marginTopAfter={-50}
                         marginBottomBefore={-75} marginBottomAfter={20} isMobile={isMobile}
                         extraClass={'mediaTopImg'}
      />
      {/*<h2 className='mediaAlbumTitle'>Album Art</h2>*/}
      <div className='mediaDesc mediaAlbumDesc'>
        Over the years, I've had the privilege creating album and single artwork for some of the most talented up-and-coming bands, such as: <a
        href={'https://open.spotify.com/artist/316hBqEkOfIJ4EaOGiIle9?si=8def88f74c50452a'}>Big
        Trousers</a>, <a
        href={"https://open.spotify.com/artist/4W9f5emYaGQnxlcZ6SOhQk?si=qNtLrWXsSne4q2HUCUBUlg"}>Unlucky @
        Midnight</a>, <a
        href={"https://open.spotify.com/artist/4lNieU3aPlri0kbWmd5UoC?si=tjykQozGTyaNUVoqOJYPyA"}>Electric
        Milk</a> and <a href={"https://open.spotify.com/artist/4VsWKLVdPMA7QC6wNqlqc7?si=5837fd9dc3a64920"}>William
        Reigate</a>.
        <br/><br/>
        But it's not just about the album covers – I also help bands get the word out with eye-catching posters and social media graphics.
        <br/><br/>
        I've also tackled logo design for clients, including: <a href={'https://www.instagram.com/tegalucat//'}>Tegalü Cat</a>, <a
        href={'https://www.instagram.com/nokitegram/'}>No Kite</a>,
        the <a href={'https://www.instagram.com/kclcybersociety/'}>King's College London Cyber Security
        Society</a> , <a href={'https://www.instagram.com/tunguskaband/'}>Tungüska</a>, as well as logos for my
        own projects.
      </div>

      <ProjectHomeDisplay arr={albums} scrollContainer={scrollContainer} displayType={`mediaAlbumGrid`} setOpen={setOpen} setDialogContent={setDialogContent} isMobile={isMobile} setDialogStyle={setDialogStyle} />

      <br/>
      <ProjectHomeDisplay arr={posters} scrollContainer={scrollContainer} displayType={`mediaPosterGrid`} setOpen={setOpen} setDialogContent={setDialogContent} isMobile={isMobile} setDialogStyle={setDialogStyle} />

      <br/>
      <ProjectHomeDisplay arr={logos} scrollContainer={scrollContainer} displayType={`mediaLogoGrid`} setOpen={setOpen} setDialogContent={setDialogContent} isMobile={isMobile} setDialogStyle={setDialogStyle} />
    </div>
  )
}

export default Design