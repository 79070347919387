import {useState} from "react";
import {useNavigate} from "react-router-dom";

import './homeBubble.css';
import './homeBubbleMobile.css';

const HomeBubble = (props) => {
  let navigate = useNavigate();
  let {index, nav, icon, text, clicked} = props;
  let [hover, setHover] = useState(false);

  return (
    <>
      <div onContextMenu={(event) => {
        event.preventDefault();
        event.stopImmediatePropagation();
        return false;
      }}
        className={`iconContainer icon${index} noSelect`}
        onClick={() => {
          navigate(`/${nav}`)
        }}
        onMouseEnter={() => {
          setHover(true)
        }}
        onMouseLeave={() => {
          setHover(false)
        }}
      >
        <img onContextMenu={(event) => {
          event.preventDefault();
          event.stopImmediatePropagation();
          return false;
        }} className={`icon icon${index}${clicked}`} src={icon} alt={`Icon ${index}`}/>
        <div className={'bubbleFill'}></div>
        <div className={`homeBubbleText homeBubbleText${hover}`}>{text}</div>
      </div>
    </>
  )
}

export default HomeBubble;